import { ChainId, Token, WETH } from '@pancakeswap/sdk'

export const MAIN_TOKEN = {
  [ChainId.BSC]: new Token(
    ChainId.BSC,
    '0xD80ED841999751C7c7f087A80699F704fEF5C900',
    18,
    'Riecs Network',
    'RICE',
    'https://www.ricesnetwork.com/'
  ),

  [ChainId.BSC_TESTNET]: new Token(
    ChainId.BSC_TESTNET,
    '0x255ACaCB162Deb526Df79EFA31282378c6FDbA09',
    18,
    'Riecs Network',
    'RICE',
    'https://www.ricesnetwork.com/'
  )

}

export const USDT = {
  [ChainId.BSC]: new Token(
    ChainId.BSC,
    '0x55d398326f99059fF775485246999027B3197955',
    18,
    'USDT',
    'USDT',
    'https://www.binance.com/',
  ),
  [ChainId.BSC_TESTNET]: new Token(
    ChainId.BSC_TESTNET,
    '0x337610d27c682E347C9cD60BD4b3b107C9d34dDd',
    18,
    'USDT',
    'Tether USD',
    'https://www.centre.io/usdt'
  ),
}

export const BUSD = {
  [ChainId.BSC]: new Token(
    ChainId.BSC,
    '0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56',
    18,
    'BUSD',
    'BUSD',
    'https://www.binance.com/',
  ),
  [ChainId.BSC_TESTNET]: new Token(
    ChainId.BSC_TESTNET,
    '0xeD24FC36d5Ee211Ea25A80239Fb8C4Cfd80f12Ee',
    18,
    'BUSD',
    'BUSD',
    'https://www.binance.com/',
  ),
}

export const WBNB = {
  [ChainId.BSC]: new Token(
    ChainId.BSC,
    '0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c',
    18,
    'BNB',
    'BNB',
    'https://www.binance.com/',
  ),
  [ChainId.BSC_TESTNET]: new Token(
    ChainId.BSC_TESTNET,
    '0xae13d989daC2f0dEbFf460aC112a837C89BAa7cd',
    18,
    'BNB',
    'BNB',
    'https://www.binance.com/',
  ),
}

export const CBN = {
  [ChainId.BSC]: new Token(
    ChainId.BSC,
    '0x2CB040ce31756830C1E0FCb276f2Bb4a32E5477f',
    18,
    'CBN',
    'carbonium',
    'https://www.ricesnetwork.com/'
  ),
  [ChainId.BSC_TESTNET]: new Token(
    ChainId.BSC_TESTNET,
    '0x4527C4D49D80DD663e6bAd406554E3Fc226249fb',
    18,
    'CBN',
    'carbonium',
    'https://www.ricesnetwork.com/'
  ),
}

export const MALI = {
  [ChainId.BSC]: new Token(
    ChainId.BSC,
    '0x88d8a4134454F07791CD0094dc54273D7FB880C0',
    18,
    'MALI',
    'MALIRICE',
    'https://www.ricesnetwork.com/'
  ),
  [ChainId.BSC_TESTNET]: new Token(
    ChainId.BSC_TESTNET,
    '0x7B3E14386463A18aA9Ca8Ea3dAF534313F3695EE',
    18,
    'MALI',
    'MALIRICE',
    'https://www.ricesnetwork.com/'
  ),
}

export const BKR = {
  [ChainId.BSC]: new Token(
    ChainId.BSC,
    '0xF3e8bAd99afd2287A925E54Cb9488f34EFC670e3',
    18,
    'BKR',
    'BKR',
    'https://www.ricesnetwork.com/'
  ),
  [ChainId.BSC_TESTNET]: new Token(
    ChainId.BSC_TESTNET,
    '0xFF0E9946D0b596835C2Acc41eb7FDef30579D6fF',
    18,
    'BKR',
    'BKR',
    'https://www.ricesnetwork.com/'
  ),
}

export const SWAP = {
  [ChainId.BSC]: MAIN_TOKEN[ChainId.BSC],
  [ChainId.BSC_TESTNET]: MAIN_TOKEN[ChainId.BSC_TESTNET],
}



export const bscTokens = {
  wbnb: WETH[ChainId.BSC],
  // bnb here points to the wbnb contract. Wherever the currency BNB is required, conditional checks for the symbol 'BNB' can be used
  bnb: WBNB[ChainId.BSC],
  busd : BUSD[ChainId.BSC],
  usdt : USDT[ChainId.BSC],
  bkr : BKR[ChainId.BSC],
  cake : MAIN_TOKEN[ChainId.BSC],
  mali : MALI[ChainId.BSC],
  cbn : CBN[ChainId.BSC]
}

export const bscTestnetTokens = {
  wbnb: WETH[ChainId.BSC_TESTNET],
  // bnb here points to the wbnb contract. Wherever the currency BNB is required, conditional checks for the symbol 'BNB' can be used
  bnb: WBNB[ChainId.BSC_TESTNET],
  busd : BUSD[ChainId.BSC_TESTNET],
  usdt : USDT[ChainId.BSC_TESTNET],
  bkr : BKR[ChainId.BSC_TESTNET],
  cake : MAIN_TOKEN[ChainId.BSC_TESTNET],
  mali : MALI[ChainId.BSC_TESTNET],
  cbn : CBN[ChainId.BSC_TESTNET]
}

